import React from "react";

import { useMessage } from "@messageformat/react";
import { Notification, NotificationStatus, Paragraph } from "@purpurds/purpur";
import { deleteMessage } from "@telia/b2b-message-service";
import { B2bSspaLinkReact } from "@telia/b2b-sspa-link-react";
import { MessageType } from "@telia/b2b-message-service/@types/types";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import styles from "../styles/MessageList.module.scss";

export type MessageListItem = MessageType & {
  nodeRef?: React.RefObject<HTMLDivElement>;
};

type Props = {
  messages: MessageListItem[];
  toastsLimit: number;
};

const MessageList = ({ messages, toastsLimit }: Props) => (
  <div data-testid="messages">
    <TransitionGroup>
      {messages
        .reverse()
        .filter((_, index) => index < toastsLimit)
        .map((message) => (
          <CSSTransition
            key={message.id}
            nodeRef={message.nodeRef}
            timeout={300}
            classNames="notification"
          >
            <div
              ref={message.nodeRef}
              data-testid="message-list-item"
              className={styles.notification}
            >
              <Toast {...message} />
            </div>
          </CSSTransition>
        ))}
    </TransitionGroup>
  </div>
);

export default MessageList;

const Toast = ({ id, title, body, action, status }: MessageType) => (
  <Notification
    status={status as NotificationStatus}
    id={id}
    role="alert"
    heading={title}
    headingTag="h2"
    onClose={() => deleteMessage(id)}
    closeButtonAllyLabel={useMessage("a11yClose")}
  >
    <Paragraph variant="paragraph-100">
      {body}{" "}
      {action && (
        <span>
          <br />
          <B2bSspaLinkReact variant="navigation" href={action.href} disableVisited>
            {action.label}
          </B2bSspaLinkReact>
        </span>
      )}
    </Paragraph>
  </Notification>
);
