// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LzOddfHg_wQxDSospdfI{position:fixed;inset:-1rem 1rem;z-index:299;display:flex;flex-direction:column;justify-content:flex-end;align-items:flex-end;pointer-events:none}.vKuWpvxacA6KbFTFpAdy{position:relative;pointer-events:all;width:43rem}.Y0PUVHsUOGvR2lLzvb1m{display:flex;justify-content:end;margin-bottom:var(--purpur-spacing-200)}.sEgVzllLGGvIjiiAMS8K{text-align:center;height:var(--purpur-spacing-300)}.ulex6hz6qp17JcOlQaIp p{color:var(--purpur-color-functional-white)}`, "",{"version":3,"sources":["webpack://./src/styles/Messages.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA,sBACE,cAAA,CACA,gBAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,wBAAA,CACA,oBAAA,CACA,mBAAA,CAEA,sBACE,iBAAA,CACA,kBAAA,CACA,WCfiB,CDkBnB,sBACE,YAAA,CACA,mBAAA,CACA,uCAAA,CAGF,sBACE,iBAAA,CACA,gCAAA,CAGE,wBACE,0CAAA","sourcesContent":["@import \"./variables.scss\";\n\n.messages {\n  position: fixed;\n  inset: -1rem 1rem;\n  z-index: 299;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  align-items: flex-end;\n  pointer-events: none;\n\n  &__container {\n    position: relative;\n    pointer-events: all;\n    width: $notification-width;\n  }\n\n  &__close-all {\n    display: flex;\n    justify-content: end;\n    margin-bottom: var(--purpur-spacing-200);\n  }\n\n  &__more {\n    text-align: center;\n    height: var(--purpur-spacing-300);\n\n    &--on-dark {\n      p {\n        color: var(--purpur-color-functional-white);\n      }\n    }\n  }\n}\n","$notification-width: 43rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"messages": `LzOddfHg_wQxDSospdfI`,
	"messages__container": `vKuWpvxacA6KbFTFpAdy`,
	"messages__close-all": `Y0PUVHsUOGvR2lLzvb1m`,
	"messages__more": `sEgVzllLGGvIjiiAMS8K`,
	"messages__more--on-dark": `ulex6hz6qp17JcOlQaIp`
};
export default ___CSS_LOADER_EXPORT___;
