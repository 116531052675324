// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../common/temp/node_modules/.pnpm/css-loader@6.8.1_webpack@5.94.0/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Z8hdBK4dMci4LDhihD9E{width:43rem;margin:var(--purpur-spacing-100) 0}`, "",{"version":3,"sources":["webpack://./src/styles/MessageList.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA,sBACE,WCHmB,CDInB,kCAAA","sourcesContent":["@import \"./variables.scss\";\n\n.notification {\n  width: $notification-width;\n  margin: var(--purpur-spacing-100) 0;\n}\n","$notification-width: 43rem;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notification": `Z8hdBK4dMci4LDhihD9E`
};
export default ___CSS_LOADER_EXPORT___;
