import React from "react";

import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";

import { Scaffolding } from "./Scaffolding";

import Messages from "./components/Messages";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "b2x-teaser-cluster": unknown;
      "b2x-slider": unknown;
    }
  }
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  renderType: "createRoot",
  rootComponent: () => (
    <Scaffolding>
      <Messages />
    </Scaffolding>
  ),
});

export const { bootstrap, mount, unmount } = lifecycles;
