import React, { createRef, useRef } from "react";
import { useEffect, useState } from "react";

import cn from "classnames";
import { useMessage } from "@messageformat/react";
import { Button, Paragraph } from "@purpurds/purpur";
import { CSSTransition } from "react-transition-group";
import { deleteAllMessages, subscribe } from "@telia/b2b-message-service";
import type { MessageType } from "@telia/b2b-message-service/@types/types";
import { useIntersect } from "../hooks/useIntersect";

import MessageList, { MessageListItem } from "./MessageList";

import "../styles/globals.scss";
import styles from "../styles/Messages.module.scss";

const Messages = () => {
  const [messages, setMessages] = useState<MessageListItem[]>([]);
  const displayedToastsLimit = 3;

  useEffect(() => {
    subscribe((state: { messages: MessageType[] }) => {
      setMessages(
        state.messages.map((message) => ({
          ...message,
          nodeRef: createRef(),
        }))
      );
    });
  }, []);

  return (
    <div data-testid="b2b-message-app-react" className={styles.messages}>
      <div className={styles.messages__container}>
        {messages.length > 1 && <DeleteAllMessagesButton />}

        <MessageList messages={messages} toastsLimit={displayedToastsLimit} />

        <OverDisplayLimit
          amount={messages.length - displayedToastsLimit}
          show={messages.length > displayedToastsLimit}
        />
      </div>
    </div>
  );
};

export default Messages;

type OverDisplayLimitProps = {
  amount: number;
  show: boolean;
};

const OverDisplayLimit = ({ amount, show }: OverDisplayLimitProps) => {
  const nodeRef = useRef(null);

  const footer = document.querySelector("telia-footer");
  const { isIntersecting } = useIntersect<Element>({ element: footer });

  return (
    <div
      data-testid="messages-more"
      className={cn(
        styles["messages__more"],
        isIntersecting ? styles["messages__more--on-dark"] : null
      )}
    >
      <CSSTransition
        nodeRef={nodeRef}
        in={show}
        timeout={300}
        classNames="more-transition"
        unmountOnExit
      >
        <div ref={nodeRef}>
          <Paragraph variant="paragraph-100">
            <strong>{useMessage("moreMessages", { amount })}</strong>
          </Paragraph>
        </div>
      </CSSTransition>
    </div>
  );
};

const DeleteAllMessagesButton = () => (
  <div className={styles["messages__close-all"]}>
    <Button
      data-testid="close-all"
      variant="secondary"
      size="sm"
      type="button"
      aria-label={useMessage("a11yCloseAll")}
      onClick={deleteAllMessages}
    >
      {useMessage("closeAll")}
    </Button>
  </div>
);
