import { MutableRefObject, useState, useEffect } from "react";

type UseIntersectState = {
  isIntersecting: boolean;
  top: number | undefined;
  bottom: number | undefined;
};

type UseInterSectProps<T extends Element | null> = {
  ref?: MutableRefObject<T> | null;
  element?: T | null;
  options?: IntersectionObserverInit;
};

export const useIntersect = <T extends Element | null>({
  element,
  options,
  ref,
}: UseInterSectProps<T>): UseIntersectState => {
  const [state, setState] = useState<UseIntersectState>({
    isIntersecting: false,
    top: undefined,
    bottom: undefined,
  });

  const current = element || ref?.current;

  // Destructing here to not get an endless loop
  const { root, rootMargin, threshold } = options || {};
  useEffect(() => {
    if (current) {
      const observer = new IntersectionObserver(
        (entries) => {
          const { top, bottom } = current.getBoundingClientRect();

          setState({
            isIntersecting: entries.some(({ isIntersecting }) => isIntersecting),
            top,
            bottom,
          });
        },
        { root, rootMargin, threshold }
      );

      observer.observe(current);
      return () => observer.unobserve(current);
    }
    return undefined;
  }, [current, root, rootMargin, threshold]);

  return state;
};
